import React from 'react'

const Homepage = () => {
  return (
    <div className='homepage main'>
      <h1>Dein Sound:</h1>
      <h1 >Meine Leidenschaft!</h1>
    </div>
  )
}

export default Homepage
